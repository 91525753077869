import * as React from "react";

import { DateTime } from "luxon";

import Typography from "../Typography/Typography";

import { MutableSessionContext } from "~/lib/context";
import { useLinguiLanguage } from "~/lib/hooks";

const EmployeeTsHeader = ({
  showDate = false,
  oldVersion = false
}: {
  showDate?: boolean;
  oldVersion?: boolean;
}): JSX.Element => {
  const { session } = React.useContext(MutableSessionContext);
  const language = useLinguiLanguage();

  const formatDate = (
    date = "",
    includeTime = false,
    locale?: string
  ): string => {
    const dateObj = date === "" ? DateTime.now() : DateTime.fromISO(date);

    return includeTime
      ? new Intl.DateTimeFormat(locale || "en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          timeZoneName: "short"
        }).format(dateObj.toJSDate())
      : new Intl.DateTimeFormat(locale || "en-US", {
          weekday: "short",
          month: "long",
          day: "numeric"
        }).format(dateObj.toJSDate());
  };

  const LogoIcon = (): JSX.Element => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.271 4.59619H25.4649L14.5629 23.4916L7.66238 12.7838H10.8339L14.4762 18.6724L19.1034 10.6262C17.4619 9.42146 15.4734 8.77604 13.4293 8.78219C8.12695 8.77604 3.82191 13.0358 3.81571 18.2975C3.80952 23.5592 8.10219 27.8312 13.4046 27.8374C18.7069 27.8435 23.012 23.5838 23.0182 18.3221C23.0182 17.1357 22.7952 15.9555 22.3616 14.8491L28.271 4.59619Z"
        fill="#263D39"
      />
    </svg>
  );

  if (oldVersion) {
    return (
      <header
        style={{
          alignItems: "center",
          backgroundColor: "#ffffff",
          borderBottom: "solid 1px #c1c7c6",
          color: "#6f7b79",
          // position: "fixed",
          display: "grid",
          gridTemplateColumns: "2.5rem 1fr",
          height: "3rem",
          left: 0,
          padding: "0 1.5rem",
          textTransform: "uppercase",
          top: 0,
          width: "100%",
          fontSize: "0.75rem"
        }}
        className="fs-6 z-[2]"
      >
        <LogoIcon />
        <span>{formatDate("", false, language)}</span>
      </header>
    );
  }

  return (
    <header className="flex flex-row py-2 px-4 h-[48px] gap-4 max-h-[48px] bg-white z-[3] items-center border-b border-b-ts-gray-80 sticky top-0">
      <LogoIcon />
      <Typography variant="h6" className="!normal-case text-ts-teal-30">
        {session?.company?.name || ""}
      </Typography>
      {showDate && <span>{formatDate("", false, "en-US")}</span>}
    </header>
  );
};

export default EmployeeTsHeader;
