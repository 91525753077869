import React, { useContext, useEffect, useRef, useState } from "react";

import { Trans } from "@lingui/react";
import { CaretDoubleDown, CaretRight } from "@phosphor-icons/react";
import { DateTime } from "luxon";

import { AttendanceEvent, AttendancePoints } from "../api/surveys";

import * as api from "~/api";
import "./SurveyAbsenceHistory.scss";
import { ConversationErrorPage } from "~/components";
import { ReactComponent as PartyPopperSvg } from "~/images/party-popper.svg";
import { MutableSessionContext } from "~/lib/context";
import { useLinguiLanguage } from "~/lib/hooks";
import {
  getLabelDisplay,
  getLabelColorDarkened,
  getLabelColorLightened
} from "~/lib/status";
import AttendancePointsBar from "~/mui-components/AttendancePointsBar/AttendancePointsBar";
import Button from "~/mui-components/Button/Button";
import { AttendanceHistory } from "~employees";

interface SurveyProps {
  tokenFromScreener?: string;
  employeeFromScreener?: api.Employee;
  setShowAttendanceHistory: Function;
  showReportAbsenceButton?: boolean;
}

const SurveyAbsenceHistory: React.FC<SurveyProps> = ({
  employeeFromScreener,
  setShowAttendanceHistory,
  showReportAbsenceButton = true
}) => {
  const [attendanceHistory, setAttendanceHistory] = useState<AttendanceEvent[]>(
    []
  );
  const [attendancePolicy, setAttendancePolicy] =
    useState<api.AttendancePolicy | null>(null);
  const [attendancePoints, setAttendancePoints] =
    useState<AttendancePoints | null>(null);
  const [areEventsPointsSynced, setAreEventsPointsSynced] =
    useState<boolean>(true);
  const [isAttendanceHistoryData, setIsAttendanceHistoryData] = useState(true);
  const [showError, setShowError] = useState(false);
  const [divisionConfig, setDivisionConfig] = useState<{
    [key in api.DivisionConfig]: boolean;
  }>(
    Object.fromEntries(
      Object.values(api.DivisionConfig).map(key => [key, true])
    ) as { [key in api.DivisionConfig]: boolean }
  );
  const { session } = useContext(MutableSessionContext);
  const scrollBottomRef = useRef<HTMLDivElement>(null);
  const language = useLinguiLanguage();
  const employee = employeeFromScreener ?? session?.employee;
  const labels = session?.labels;

  const fetchAttendanceHistory = async (
    employee: api.Employee | undefined,
    labels: api.LabelInfoMap,
    language: string
  ): Promise<void> => {
    if (!(employee?.company_id && employee?.id)) {
      setShowError(true);
      return;
    }

    try {
      const response = await api.getEmployeeAttendanceHistory(
        employee.company_id,
        employee.id
      );

      if (response.ok && "data" in response && response.data !== null) {
        const responseData = response.data.eventCounts.map(
          (event: AttendanceEvent) => ({
            ...event,
            displayText: getLabelDisplay(event.label, labels, language)
          })
        );
        setAttendanceHistory(responseData);

        if ("attendancePoints" in response.data) {
          setAttendancePoints(response.data.attendancePoints ?? null);
        }
      } else {
        setShowError(true);
      }
    } catch (err) {
      setShowError(true);
    }
  };

  const fetchAttendancePolicy = async (
    employee: api.Employee | undefined
  ): Promise<void> => {
    if (!(employee?.company_id && employee?.id)) {
      return;
    }

    try {
      const response = await api.getAttendancePolicy(
        employee.company_id,
        employee.id
      );

      if (response.ok && "data" in response && response.data !== null) {
        setAttendancePolicy({
          ...response.data
        });
      }
    } catch (err) {
      setShowError(true);
    }
  };

  const fetchEventsPointsSynced = async (
    employee: api.Employee | undefined
  ): Promise<void> => {
    if (!employee?.id) {
      return;
    }

    try {
      const response = await api.getEventsPointsSynced(
        employee.company_id,
        employee.id
      );

      if (response.ok && "data" in response && response.data !== null) {
        setAreEventsPointsSynced(response.data.synced);
      }
    } catch (err) {
      setShowError(true);
    }
  };

  const fetchDivisionConfig = async (
    employee: api.Employee | undefined
  ): Promise<void> => {
    try {
      const company_id = employee?.company_id;
      const division_id = employee?.division?.id;
      if (!company_id || !division_id) {
        return;
      }
      const response = await api.getDivisionConfigs(company_id, division_id);

      if (response.ok && "data" in response && response.data !== null) {
        setDivisionConfig(response.data);
      }
    } catch (err) {
      setShowError(true);
    }
  };

  const LogoIcon = (): JSX.Element => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.271 4.59619H25.4649L14.5629 23.4916L7.66238 12.7838H10.8339L14.4762 18.6724L19.1034 10.6262C17.4619 9.42146 15.4734 8.77604 13.4293 8.78219C8.12695 8.77604 3.82191 13.0358 3.81571 18.2975C3.80952 23.5592 8.10219 27.8312 13.4046 27.8374C18.7069 27.8435 23.012 23.5838 23.0182 18.3221C23.0182 17.1357 22.7952 15.9555 22.3616 14.8491L28.271 4.59619Z"
        fill="#263D39"
      />
    </svg>
  );

  const UnavailableIcon = (): JSX.Element => (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 9C12.9142 9 13.25 9.33579 13.25 9.75V13.5C13.25 13.9142 12.9142 14.25 12.5 14.25C12.0858 14.25 11.75 13.9142 11.75 13.5V9.75C11.75 9.33579 12.0858 9 12.5 9Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.376 2.55208C11.7175 2.35405 12.1052 2.24976 12.5 2.24976C12.8947 2.24976 13.2825 2.35405 13.624 2.55208C13.9649 2.74978 14.2476 3.03389 14.4437 3.37573L14.4447 3.37747L22.6932 17.625C22.8904 17.9664 22.9943 18.3537 22.9947 18.748C22.995 19.1423 22.8918 19.5298 22.6952 19.8716C22.4987 20.2134 22.2158 20.4975 21.8748 20.6956C21.5339 20.8936 21.1469 20.9986 20.7526 21L20.75 21H4.24997L4.24732 21C3.85304 20.9986 3.46604 20.8936 3.1251 20.6956C2.78416 20.4975 2.50125 20.2134 2.30471 19.8716C2.10817 19.5298 2.0049 19.1423 2.00525 18.748C2.0056 18.3537 2.10955 17.9664 2.3067 17.625L10.5553 3.37747L10.5563 3.37573C10.7523 3.03388 11.035 2.74978 11.376 2.55208ZM11.2062 3.74999L11.8553 4.12577L3.60573 18.375C3.54013 18.4887 3.50537 18.618 3.50525 18.7493C3.50513 18.8808 3.53956 19.0099 3.60507 19.1239C3.67058 19.2378 3.76489 19.3325 3.87853 19.3985C3.99189 19.4644 4.12051 19.4994 4.25159 19.5H20.7483C20.8794 19.4994 21.008 19.4644 21.1214 19.3985C21.235 19.3325 21.3293 19.2378 21.3949 19.1239C21.4604 19.0099 21.4948 18.8808 21.4947 18.7493C21.4946 18.6181 21.46 18.4892 21.3945 18.3754L13.1428 4.12252C13.078 4.00927 12.9844 3.91515 12.8715 3.84969C12.7586 3.78423 12.6305 3.74976 12.5 3.74976C12.3695 3.74976 12.2413 3.78423 12.1284 3.84969C12.0156 3.91515 11.922 4.00926 11.8572 4.12252L11.2062 3.74999Z"
        fill="black"
      />
      <path
        d="M12.5 18C13.1213 18 13.625 17.4963 13.625 16.875C13.625 16.2537 13.1213 15.75 12.5 15.75C11.8787 15.75 11.375 16.2537 11.375 16.875C11.375 17.4963 11.8787 18 12.5 18Z"
        fill="black"
      />
    </svg>
  );

  const formatDate = (
    date = "",
    includeTime = false,
    locale?: string
  ): string => {
    const dateObj = date === "" ? DateTime.now() : DateTime.fromISO(date);

    return includeTime
      ? new Intl.DateTimeFormat(locale || "en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          timeZoneName: "short"
        }).format(dateObj.toJSDate())
      : new Intl.DateTimeFormat(locale || "en-US", {
          weekday: "short",
          month: "long",
          day: "numeric"
        }).format(dateObj.toJSDate());
  };

  useEffect(() => {
    fetchAttendanceHistory(employee, labels, language);
    fetchAttendancePolicy(employee);
    fetchEventsPointsSynced(employee);
    fetchDivisionConfig(employee);
  }, [employee, labels, language]);

  const scrollToBottom = (): void => {
    scrollBottomRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  };

  const asOfDate = formatDate(attendancePoints?.updated, true, language);

  const PointsBoard = (): JSX.Element => (
    <div className="text-center !p-4">
      {!attendancePoints && (
        <div className="text-1-5xl font-semibold">
          <Trans
            id="surveyAbsenceHistory.header.name"
            values={[employee?.first_name]}
          >
            Hi, {employee?.first_name}.
          </Trans>
        </div>
      )}
      <div>
        <Trans id="surveyAbsenceHistory.header.description">
          In the last 12 months, TeamSense has recorded:
        </Trans>
      </div>
      {attendanceHistory.length > 0 ? (
        <div
          className={`attendance-events event-count-${attendanceHistory.length}`}
        >
          {attendanceHistory.map((event, index) => (
            <div key={event.label}>
              <div className="stat-count">{event.count}</div>
              <div
                className="rounded-pill"
                style={{
                  backgroundColor: getLabelColorLightened(event.label, labels),
                  color: getLabelColorDarkened(event.label, labels)
                }}
              >
                {event.displayText}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="bg-teal-97 inline-block leading-tight mt-3 rounded-lg p-5 text-center text-hs-dark">
          <PartyPopperSvg className="inline mb-3" />
          <p>
            <Trans id="surveyAbsenceHistory.header.congrats">
              No absences recorded. Nice job!
            </Trans>
          </p>
        </div>
      )}
    </div>
  );

  return showError ? (
    <ConversationErrorPage />
  ) : (
    <div className="ts">
      <div className="attendance-history bg-white">
        <header className="fs-6 z-[2]">
          <LogoIcon />
          <span>{formatDate("", false, language)}</span>
        </header>
        <div className="text-center !p-4">
          {attendancePoints && !attendancePolicy && (
            <div className="drop-shadow-container">
              <div className="text-1-5xl font-semibold">
                <Trans
                  id="surveyAbsenceHistory.header.name"
                  values={[employee?.first_name]}
                >
                  Hi, {employee?.first_name}.
                </Trans>
              </div>
              <div>
                <Trans id="surveyAbsenceHistory.points.annotation">
                  Your points balance is:
                </Trans>
              </div>
              {attendancePoints?.count === null ? (
                <>
                  <div className="d-flex justify-center m-auto mt-3">
                    <UnavailableIcon />
                  </div>
                  <div className="font-bold mb-3">
                    <Trans id="surveyAbsenceHistory.points.unavailable">
                      Currently Unavailable
                    </Trans>
                  </div>
                  <div className="fs-6 text-gray-500">
                    <Trans id="surveyAbsenceHistory.points.disclaimer">
                      Please contact your supervisor for more info.
                    </Trans>
                  </div>
                </>
              ) : (
                <>
                  <div className="stat-count mt-3">
                    {attendancePoints.count}
                  </div>
                  <div className="font-bold mb-3">
                    <Trans id="surveyAbsenceHistory.points.title">
                      Attendance Points
                    </Trans>
                  </div>
                  <div className="fs-6 text-gray-500">
                    <Trans id="surveyAbsence.points.asof" values={{ asOfDate }}>
                      as of {asOfDate}
                    </Trans>
                  </div>
                </>
              )}
            </div>
          )}

          {attendancePolicy && divisionConfig.attendance_points_display ? (
            <>
              <div className="text-1-5xl font-semibold pb-2">
                <Trans
                  id="surveyAbsenceHistory.header.name"
                  values={[employee?.first_name]}
                >
                  Hi, {employee?.first_name}.
                </Trans>
              </div>
              <div className="my-3">
                <AttendancePointsBar
                  attendancePointsUsed={attendancePoints?.count || 0}
                  showAllSegmentNumbers={true}
                  thresholds={attendancePolicy.points_thresholds}
                  canSeeInfoDetails={true}
                  infoDetailsText={
                    divisionConfig.attendance_points_policy
                      ? attendancePolicy.summary.join("\n")
                      : ""
                  }
                  lastUpdatedDate={attendancePoints?.updated}
                  unsynced={!areEventsPointsSynced}
                  showVariants={false}
                  showDate={true}
                  unavailable={attendancePoints?.count === null}
                />
              </div>
            </>
          ) : (
            <div className="drop-shadow-container my-3">
              <PointsBoard />
            </div>
          )}

          <div className="flex flex-column gap-3">
            {showReportAbsenceButton && (
              <Button
                size="large"
                endIcon={<CaretRight weight="bold" size={20} />}
                className="!justify-between"
                onClick={() => setShowAttendanceHistory(false)}
              >
                <Trans id="surveyAbsenceHistory.button.reportAbsence">
                  Report Absence
                </Trans>
              </Button>
            )}
            {isAttendanceHistoryData && (
              <Button
                color="secondary"
                size="large"
                endIcon={<CaretDoubleDown weight="bold" size={20} />}
                className="!justify-between"
                onClick={() => scrollToBottom()}
              >
                <Trans id="surveyAbsenceHistory.button.viewAttendanceHistory">
                  View Attendance History
                </Trans>
              </Button>
            )}
          </div>
        </div>
      </div>
      <div ref={scrollBottomRef} className="invisible-anchor" />
      {isAttendanceHistoryData && (
        <div className="attendance-history-timeline">
          <AttendanceHistory
            employee={employee as api.Employee}
            surveyMode={true}
            setIsAttendanceHistoryData={setIsAttendanceHistoryData}
          />
        </div>
      )}
    </div>
  );
};

export { SurveyAbsenceHistory };
