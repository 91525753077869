import React, { FunctionComponent, useContext, useState } from "react";
import { useParams } from "react-router-dom";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context";
import { useEffectOnce } from "~/lib/hooks";
import { Survey } from "~/views/Survey";
import { SurveyAbsenceHistory } from "~/views/SurveyAbsenceHistory";

interface ParamTypes {
  token: string;
}

interface PreSurveyProps {
  isValidEmployeeToken?: boolean;
  onSetShowAttendanceHistory?: (showAttendanceHistory: boolean) => void;
  triggerSurvey?: boolean;
  showReportAbsenceButton?: boolean;
}

export const PreSurvey: FunctionComponent<PreSurveyProps> = ({
  isValidEmployeeToken = false,
  onSetShowAttendanceHistory = undefined,
  triggerSurvey = false,
  showReportAbsenceButton = true
}) => {
  const { session } = useContext(MutableSessionContext);
  /*
   * Determine which pre-survey component to show, if applicable
   * Show the survey if none of the pre-survey components are happening
   */
  const [showAttendanceHistory, setShowAttendanceHistory] = useState<
    boolean | undefined
  >();

  const shouldShowAttendanceHistory = async (token: string): Promise<void> => {
    let showHistory = false;
    let response: api.APIResponse<Boolean> | undefined;
    if (isValidEmployeeToken) {
      setShowAttendanceHistory(true);
      return;
    }
    try {
      response = await api.retrieveShowAttendanceHistory(
        session.company?.id,
        session.employee?.id,
        token
      );
    } catch {}

    if (response?.ok) {
      showHistory = response.data as boolean;
    }
    setShowAttendanceHistory(!triggerSurvey && showHistory);
  };

  const { token: paramsToken } = useParams<ParamTypes>();

  useEffectOnce(() => {
    shouldShowAttendanceHistory(paramsToken);
  });

  const surveySetShowAttendanceHistory = (
    showAttendanceHistory: boolean
  ): void => {
    if (onSetShowAttendanceHistory) {
      onSetShowAttendanceHistory(Boolean(showAttendanceHistory));
    }
    setShowAttendanceHistory(showAttendanceHistory);
  };

  if (showAttendanceHistory === undefined) {
    return <></>;
  }

  return (
    <>
      {showAttendanceHistory ? (
        <SurveyAbsenceHistory
          setShowAttendanceHistory={surveySetShowAttendanceHistory}
          showReportAbsenceButton={showReportAbsenceButton}
        />
      ) : (
        <Survey />
      )}
    </>
  );
};
