import React, {
  CSSProperties,
  FunctionComponent,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { toast } from "react-toastify";

import { Trans } from "@lingui/react";
import * as Sentry from "@sentry/react";
import { DateTime } from "luxon";
import Mustache from "mustache";

import * as api from "~/api";
import {
  AlertConversationBadge,
  ClearConversationBadge,
  ClearWithActiveSickConversationBadge,
  ConversationErrorPage,
  ConversationHeader,
  ExpireableConversationBadge,
  ExpiredConversationBadge,
  GenericConversationBadge,
  InlineStep,
  MessageHistory
} from "~/components";
import { MutableSessionContext } from "~/lib/context";
import {
  processConversationTranslation,
  useEffectOnce,
  useLinguiLanguage,
  useLinguiLocale
} from "~/lib/hooks";
import { generateDateRangeShort, getLabelDisplay } from "~/lib/status";
import Button from "~/mui-components/Button/Button";
import { GenericErrorText, Loading } from "~common";

interface ParamTypes {
  token: string;
}

const renderBadgeWhenAppropriate = (
  labelInfoMap: api.LabelInfoMap,
  language: string,
  locale: string,
  containerStyle: CSSProperties,
  employee: api.Employee,
  surveyState: api.SurveyState,
  onStartOverForScreener?: () => any
): ReactElement | null => {
  const currentStep = surveyState.current.step;

  switch (currentStep.kind) {
    case "badge": {
      const templateHeader = processConversationTranslation(
        language,
        currentStep.header
      );
      const templateBody = processConversationTranslation(
        language,
        currentStep.body
      );
      const templateTitle = processConversationTranslation(
        language,
        currentStep.title
      );
      let context: api.BadgeContext;
      try {
        context = {
          employee,
          eventName: "",
          eventDateRangeShort: "",
          completedDate: surveyState.completed
            ? DateTime.fromISO(surveyState.completed)
                .setLocale(locale)
                .toLocaleString({
                  ...DateTime.DATE_FULL,
                  year: undefined
                })
            : "",
          completedTime: surveyState.completed
            ? DateTime.fromISO(surveyState.completed)
                .setLocale(locale)
                .toLocaleString({
                  ...DateTime.TIME_SIMPLE,
                  timeZoneName: "short"
                })
            : ""
        };
      } catch (e) {
        context = {
          employee,
          eventName: "",
          eventDateRangeShort: "",
          completedDate: surveyState.completed
            ? DateTime.fromISO(surveyState.completed)
                .setLocale("en-US")
                .toLocaleString({
                  ...DateTime.DATE_FULL,
                  year: undefined
                })
            : "",
          completedTime: surveyState.completed
            ? DateTime.fromISO(surveyState.completed)
                .setLocale("en-US")
                .toLocaleString({
                  ...DateTime.TIME_SIMPLE,
                  timeZoneName: "short"
                })
            : ""
        };
      }

      if (
        surveyState.outcome?.employeeEvents &&
        surveyState.outcome.employeeEvents.length > 0
      ) {
        const chosenEvent =
          surveyState.outcome.employeeEvents.find(
            ee => ee.label.label_set !== api.LabelSet.CovidHealthStatus
          ) || surveyState.outcome.employeeEvents[0];

        context.eventName = chosenEvent?.label?.name
          ? getLabelDisplay(chosenEvent.label.name, labelInfoMap, language)
          : "";
        context.eventDateRangeShort = generateDateRangeShort(
          locale,
          employee.timezone,
          chosenEvent
        );
      }
      return (
        <GenericConversationBadge
          employee={employee}
          style={containerStyle}
          context={context}
          hideEmployeeDetails={!currentStep.hintEmployee}
          header={
            templateHeader
              ? Mustache.render(templateHeader, context)
              : undefined
          }
          body={
            templateBody ? Mustache.render(templateBody, context) : undefined
          }
          title={
            templateTitle ? Mustache.render(templateTitle, context) : undefined
          }
          onStartOver={onStartOverForScreener}
        />
      );
    }
    case "alert": {
      const templateBody = processConversationTranslation(
        language,
        currentStep.body
      );

      switch (currentStep.hintMood) {
        case "bad": {
          const templateTitle = processConversationTranslation(
            language,
            currentStep.title
          );

          return (
            <AlertConversationBadge
              created={surveyState.created}
              style={containerStyle}
              friendlyName={employee.friendly_name}
              name={employee.name}
              employeeId={employee.external_id}
              subtitle={
                templateBody
                  ? Mustache.render(templateBody, { employee })
                  : undefined
              }
              title={
                templateTitle
                  ? Mustache.render(templateTitle, { employee })
                  : undefined
              }
              onStartOver={onStartOverForScreener}
            />
          );
        }
        case "neutral":
        case "good": {
          const { employeeEvents: events } = surveyState.outcome || {
            employeeEvents: []
          };
          let clearEvent;
          try {
            clearEvent = events.find(event => event.label?.name === "clear");
          } catch (e) {
            // NOTE: even though employeeEvents should always be present, we
            // saw a Sentry event that suggested it could be missing here, so
            // adding we're catching the error and reporting the exception:
            console.debug(
              `Failed to extract employeeEvents from surveyState: ${JSON.stringify(
                surveyState
              )}`
            );
            Sentry.captureException(e);
          }
          const expiration = clearEvent?.ended;
          return (
            <ExpireableConversationBadge
              expiration={expiration}
              badge={
                clearEvent ? (
                  <ClearConversationBadge
                    created={surveyState.created}
                    style={containerStyle}
                    expiration={expiration}
                    friendlyName={employee.friendly_name}
                    name={employee.name}
                    employeeId={employee.external_id}
                    subtitle={
                      templateBody
                        ? Mustache.render(templateBody, { employee })
                        : undefined
                    }
                    onStartOver={onStartOverForScreener}
                  />
                ) : (
                  <ClearWithActiveSickConversationBadge
                    created={surveyState.created}
                    style={containerStyle}
                    friendlyName={employee.friendly_name}
                    name={employee.name}
                    employeeId={employee.external_id}
                    onStartOver={onStartOverForScreener}
                  />
                )
              }
              expiredBadge={
                <ExpiredConversationBadge
                  created={surveyState.created}
                  style={containerStyle}
                  expiration={expiration}
                  friendlyName={employee.friendly_name}
                  name={employee.name}
                  employeeId={employee.external_id}
                  onStartOver={onStartOverForScreener}
                />
              }
            />
          );
        }
      }
    }
  }
  return null;
};

const renderStep = (
  name: string,
  step: api.Step,
  employee: api.Employee,
  answer?: api.Answer,
  surveyState?: api.SurveyState,
  onChange?: (answer?: api.Answer, valid?: boolean) => any
): ReactElement | null =>
  step.kind !== "alert" && step.kind !== "badge" ? (
    <InlineStep
      key={name}
      step={step}
      employee={employee}
      answer={answer}
      otherAnswer={
        step.kind === "date" && step.hintOtherAnswerName
          ? surveyState?.history.find(
              previousAnswer => previousAnswer.name === step.hintOtherAnswerName
            )?.answer
          : undefined
      }
      onChange={onChange}
    />
  ) : null;

interface SurveyProps {
  tokenFromScreener?: string;
  employeeFromScreener?: api.Employee;
  onStartOverForScreener?: () => any;
}

export const Survey: FunctionComponent<SurveyProps> = ({
  tokenFromScreener,
  employeeFromScreener,
  onStartOverForScreener
}) => {
  const { token: paramsToken } = useParams<ParamTypes>();
  const token = tokenFromScreener ?? paramsToken;
  const { session } = useContext(MutableSessionContext);
  const { languages = [] } = session.company ?? {};
  const match = useRouteMatch();
  const { search, pathname } = useLocation();
  const browserHistory = useHistory();
  const query = new URLSearchParams(search);
  const locale = useLinguiLocale();
  const language = useLinguiLanguage();
  const scrollBottomRef = useRef<HTMLDivElement>(null);
  const employee = employeeFromScreener ?? session?.employee;

  // State
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [isExpired, setIsExpired] = useState(false);
  const [isAdvancing, setIsAdvancing] = useState(false);
  const [surveyState, setSurveyState] = useState<api.SurveyState>();
  const [currentAnswer, setCurrentAnswer] = useState<api.Answer>();
  const [answerValid, setAnswerValid] = useState(false);

  const containerStyle: CSSProperties = { height: `${innerHeight}px` };

  const loadSurveyState = async (loadToken: string): Promise<void> => {
    let response: api.APIResponse<api.SurveyState> | undefined;
    try {
      response = await api.getSurveyState(loadToken);
      if (!query.get("started")) {
        query.set("started", "1");
        browserHistory.replace(`${pathname}?${query.toString()}`);
      }
    } catch {}

    if (response?.ok) {
      setSurveyState(response.data);
    } else if (response?.status === 404) {
      setIsExpired(true);
    } else {
      const message = (
        <div dir="auto">
          <Trans id="survey.error.network">
            We couldn't get this survey. Please check your network and try
            again. If you continue to see problems, please contact TeamSense
            customer support.
          </Trans>
        </div>
      );
      toast.error(message, {
        autoClose: false,
        closeOnClick: false
      });
    }
  };

  // Make sure when the view loads the most recent message is always visible.
  const scrollToBottom = (): void => {
    scrollBottomRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest"
    });
  };

  // Effects
  useEffectOnce(() => {
    window.addEventListener("resize", () => {
      setInnerHeight(window.innerHeight);
    });
  });

  useEffect(() => {
    scrollToBottom();
  }, [isAdvancing]);

  useEffect(() => {
    loadSurveyState(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (isExpired) {
    return <ConversationErrorPage />;
  }

  if (!employee || !surveyState) {
    return <Loading />;
  }

  if (employee && surveyState) {
    const renderedBadge = renderBadgeWhenAppropriate(
      session.labels,
      language,
      locale,
      containerStyle,
      employee,
      surveyState,
      onStartOverForScreener
    );
    if (renderedBadge) {
      return renderedBadge;
    }
  }

  const { history, options, current, created, completed, is_anonymous } =
    surveyState;

  const anonymousMessage = (
    <Trans id="survey.header.anonymous">
      Your responses to this survey will be anonymous
    </Trans>
  );

  const onAdvance = async (): Promise<void> => {
    if (answerValid) {
      setIsAdvancing(true);
      let response: api.APIResponse<api.SurveyState> | undefined;
      try {
        response = await api.advanceSurveyState(
          token,
          current.name,
          currentAnswer
        );
      } catch {}
      setIsAdvancing(false);

      if (response?.ok) {
        setCurrentAnswer(undefined);
        setAnswerValid(false);
        setSurveyState(response.data);
      } else if (response?.status === 404) {
        setIsExpired(true);
      } else {
        const isFormError = Boolean(response?.errors?.non_field_errors?.length);
        toast.error(<GenericErrorText isFormError={isFormError} />);
      }
    }
  };

  const onStartOver = async (): Promise<void> => {
    setIsAdvancing(true);
    let response: api.APIResponse<api.SurveyState> | undefined;
    try {
      response = await api.resetSurveyState(token);
    } catch {}
    setIsAdvancing(false);

    if (response?.ok) {
      setCurrentAnswer(undefined);
      setAnswerValid(false);
      setSurveyState(response.data);
      toast.success(
        <div dir="auto">
          <Trans id="survey.startOver.success">
            TeamSense has erased your previous response, so you can start over!
            Please respond again.
          </Trans>
        </div>
      );
    } else if (response?.status === 404) {
      setIsExpired(true);
    } else {
      toast.error(<GenericErrorText />);
    }
  };

  const onUndo = async (): Promise<void> => {
    setIsAdvancing(true);
    let response: api.APIResponse<api.SurveyState> | undefined;
    try {
      response = await api.undoSurveyState(token);
    } catch {}
    setIsAdvancing(false);

    if (response?.ok) {
      setCurrentAnswer(undefined);
      setAnswerValid(false);
      setSurveyState(response.data);
    } else if (response?.status === 404) {
      setIsExpired(true);
    } else {
      toast.error(<GenericErrorText />);
    }
  };

  // Set a couple of flags for COVID-19 surveys based on the
  // "symptoms" hint in ChoiceStep:
  const symptomsNonRequiredChoice =
    current?.step.kind === "choice" &&
    current.step.hint === "symptoms" &&
    !current.step.choiceSet.required;

  const noSymptomsNextButtonText =
    symptomsNonRequiredChoice &&
    currentAnswer?.kind === "choice" &&
    (currentAnswer?.value?.length ?? 0) === 0;

  const redNextButton =
    symptomsNonRequiredChoice &&
    currentAnswer?.kind === "choice" &&
    (currentAnswer?.value?.length ?? 0) > 0;

  const onChangeLanguage = (lang: string): void => {
    query.set("lang", lang);
    window.location.assign(`${match.url}?${query}`);
  };

  const NextButton = (): React.ReactElement => {
    let text = <Trans id="survey.button.next">Next</Trans>;
    if (surveyState.on_last_step) {
      text = <Trans id="survey.button.submit">Submit</Trans>;
    } else if (noSymptomsNextButtonText) {
      text = <Trans id="survey.button.noSymptoms">No Symptoms</Trans>;
    }
    return (
      <Button
        id="survey-submit-button"
        size="large"
        fullWidth
        color={redNextButton ? "error" : "primary"}
        onClick={onAdvance}
        disabled={isAdvancing || !answerValid}
      >
        {text}
      </Button>
    );
  };

  return (
    <div className="bg-white min-h-screen h-full">
      <div className="sms-survey-pane flex flex-col">
        <ConversationHeader
          isoDate={created}
          completed={Boolean(completed)}
          showUndo={options?.undo ?? false}
          showLanguages={
            (options?.showLanguages ?? false) && languages.length > 1
          }
          hasStarted={history.length > 0}
          subHeader={is_anonymous && anonymousMessage}
          onChangeLanguage={onChangeLanguage}
          onStartOver={onStartOver}
          onUndo={onUndo}
        />
        <div className="pt-6 px-6">
          <MessageHistory>
            {history.map(({ name, step, answer }) =>
              renderStep(name, step, employee, answer)
            )}
            {renderStep(
              current.name,
              current.step,
              employee,
              currentAnswer,
              surveyState,
              (answer, valid) => {
                setCurrentAnswer(answer);
                setAnswerValid(Boolean(valid));
              }
            )}
            {completed && (
              <Trans id="survey.button.surveyComplete">Survey Complete</Trans>
            )}
          </MessageHistory>

          {isAdvancing && <Loading />}

          <div className="pt-3 pb-6" dir="auto">
            {completed && onStartOverForScreener && (
              <Button size="large" fullWidth onClick={onStartOverForScreener}>
                <Trans id="badge.startOver">Start Over</Trans>
              </Button>
            )}
            {!completed && <NextButton />}
          </div>
        </div>
        <div ref={scrollBottomRef} />
      </div>
    </div>
  );
};
