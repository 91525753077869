import * as React from "react";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import MuiAlertTheme from "../Alert/AlertTheme";
import MuiButtonTheme from "../Button/ButtonTheme";
import MuiPaperTheme from "../Card/CardTheme";
import ButtonLinkTheme from "../LinkCard/LinkCardTheme";
import MuiLinkTheme from "../LinkText/LinkTextTheme";
import MultipleChoiceButtonTheme from "../MultipleChoices/MultipleChoiceButtonTheme";
import PillTheme from "../Pill/PillTheme";
import MuiTabsTheme from "../Tabs/TabsTheme";
import MuiTextFieldTheme from "../TextField/TextFieldTheme";
import MuiTypographyTheme from "../Typography/TypographyTheme";

import colors from "./colors";

// Urbanist font should be installed from Google Fonts
// https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,500;0,600;0,700;1,500;1,600;1,700&display=swap

declare module "@mui/material/styles" {
  interface Palette {
    teal: Palette["primary"];
    gold: Palette["primary"];
    coral: Palette["primary"];
    gray: Palette["primary"];
    red: Palette["primary"];
    yellow: Palette["primary"];
    green: Palette["primary"];
    blue: Palette["primary"];
    grape: Palette["primary"];
    guava: Palette["primary"];
    tangerine: Palette["primary"];
    lemon: Palette["primary"];
    lime: Palette["primary"];
    ocean: Palette["primary"];
    berry: Palette["primary"];
    plum: Palette["primary"];
    coconut: Palette["primary"];
    carrot: Palette["primary"];
  }

  interface PaletteOptions {
    teal?: PaletteOptions["primary"];
    gold?: PaletteOptions["primary"];
    coral?: PaletteOptions["primary"];
    gray?: PaletteOptions["primary"];
    red?: PaletteOptions["primary"];
    yellow?: PaletteOptions["primary"];
    green?: PaletteOptions["primary"];
    blue?: PaletteOptions["primary"];
    grape?: PaletteOptions["primary"];
    guava?: PaletteOptions["primary"];
    tangerine?: PaletteOptions["primary"];
    lemon?: PaletteOptions["primary"];
    lime?: PaletteOptions["primary"];
    ocean?: PaletteOptions["primary"];
    berry?: PaletteOptions["primary"];
    plum?: PaletteOptions["primary"];
    coconut?: PaletteOptions["primary"];
    carrot?: PaletteOptions["primary"];
  }
}

let theme = createTheme({
  palette: {
    primary: {
      main: colors.teal[40],
      contrastText: colors.white[0]
    },
    secondary: {
      main: colors.teal[30],
      contrastText: colors.teal[30]
    },
    error: {
      main: colors.red[20],
      contrastText: colors.white[0]
    },
    warning: {
      main: colors.yellow[50],
      contrastText: colors.white[0]
    },
    success: {
      main: colors.green[50],
      contrastText: colors.white[0]
    },
    info: {
      main: colors.blue[50],
      contrastText: colors.white[0]
    }
  },
  typography: {
    fontSize: 16,
    fontFamily: [
      "Urbanist",
      "system-ui",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      '"Noto Sans"',
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"'
    ].join(",")
  },
  components: {
    MuiTypography: MuiTypographyTheme,
    MuiPaper: MuiPaperTheme,
    MuiButton: {
      ...MuiButtonTheme,
      variants: [
        ...(ButtonLinkTheme.variants || []),
        ...(MultipleChoiceButtonTheme.variants || [])
      ]
    },
    MuiLink: MuiLinkTheme,
    MuiTabs: MuiTabsTheme,
    MuiChip: PillTheme,
    MuiTextField: MuiTextFieldTheme,
    MuiAlert: MuiAlertTheme
  }
});

theme = createTheme(theme, {
  palette: {
    ...theme.palette,
    teal: theme.palette.augmentColor({ color: { main: colors.teal[50] } }),
    gold: theme.palette.augmentColor({ color: { main: colors.gold[50] } }),
    coral: theme.palette.augmentColor({ color: { main: colors.coral[50] } }),
    gray: theme.palette.augmentColor({ color: { main: colors.gray[50] } }),
    red: theme.palette.augmentColor({ color: { main: colors.red[50] } }),
    yellow: theme.palette.augmentColor({ color: { main: colors.yellow[50] } }),
    green: theme.palette.augmentColor({ color: { main: colors.green[50] } }),
    blue: theme.palette.augmentColor({ color: { main: colors.blue[50] } }),
    grape: theme.palette.augmentColor({ color: { main: colors.grape[50] } }),
    guava: theme.palette.augmentColor({ color: { main: colors.guava[50] } }),
    tangerine: theme.palette.augmentColor({
      color: { main: colors.tangerine[50] }
    }),
    lemon: theme.palette.augmentColor({ color: { main: colors.lemon[50] } }),
    lime: theme.palette.augmentColor({ color: { main: colors.lime[50] } }),
    ocean: theme.palette.augmentColor({ color: { main: colors.ocean[50] } }),
    berry: theme.palette.augmentColor({ color: { main: colors.berry[50] } }),
    plum: theme.palette.augmentColor({ color: { main: colors.plum[50] } }),
    coconut: theme.palette.augmentColor({
      color: { main: colors.coconut[50] }
    }),
    carrot: theme.palette.augmentColor({ color: { main: colors.carrot[50] } })
  }
});

const ThemeLayout = ({
  children
}: React.PropsWithChildren<{}>): React.ReactElement => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default ThemeLayout;
